// src/GetPosts.js
import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

const GetPosts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const res = await fetch('/.netlify/functions/getPosts');
      const data = await res.json();
      setPosts(data);
    };
    fetchPosts();
  }, []);

  return (
    <Box>
      <Typography variant="h4">Posts</Typography>
      {posts.length === 0 ? (
        <Typography>No posts available</Typography>
      ) : (
        posts.map((post) => (
          <Box key={post.id} mb={2}>
            <Typography variant="h6">{post.title}</Typography>
            <Typography variant="body1">{post.content}</Typography>
          </Box>
        ))
      )}
    </Box>
  );
};

export default GetPosts;

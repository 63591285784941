// src/AddPost.js
import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';

const AddPost = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [response, setResponse] = useState('');

  const handleSubmit = async () => {
    const post = { title, content };
    const res = await fetch('/.netlify/functions/addPost', {
      method: 'POST',
      body: JSON.stringify(post),
    });
    const data = await res.json();
    setResponse(data);
  };

  return (
    <Box>
      <Typography variant="h4">Add a Post</Typography>
      <TextField
        label="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Content"
        value={content}
        onChange={(e) => setContent(e.target.value)}
        fullWidth
        margin="normal"
        multiline
        rows={4}
      />
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Submit
      </Button>
      {response && (
        <Box mt={2}>
          <Typography variant="body1">Response:</Typography>
          <pre>{JSON.stringify(response, null, 2)}</pre>
        </Box>
      )}
    </Box>
  );
};

export default AddPost;

import React from 'react';
import { Card, CardContent, Typography, CardMedia, Box, Button, IconButton } from '@mui/material';
import { animated } from 'react-spring';
import ShareIcon from '@mui/icons-material/Share';
import logo from '../assets/HACK.png';

const AnimatedCard = animated(Card);

const ArticleCard = ({ props, article }) => {
  const handleShare = () => {
    navigator.clipboard.writeText(article.url).then(() => {
      alert('URL copied to clipboard!');
    }).catch((error) => {
      console.error('Failed to copy URL:', error);
    });
  };

  

  return (
    <AnimatedCard style={{ ...props, height: '400px', display: 'flex', flexDirection: 'column' }}>
      <CardMedia
        component="img"
        height="200"
        image={logo}
        alt={article?.title}
      />
      <CardContent style={{ flex: 1, overflow: 'hidden' }}>
        <Typography gutterBottom variant="h5" component="div" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {article?.title}
        </Typography>
        <Typography variant="body2" color="textSecondary" style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}>
          {article?.description}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
          <Box>
           
            <IconButton aria-label="share" onClick={handleShare}>
              <ShareIcon />
            </IconButton>
          </Box>
          <Button
            variant="contained"
            color="primary"
            href={article?.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            Read more
          </Button>
        </Box>
      </CardContent>
    </AnimatedCard>
  );
};

export default ArticleCard;

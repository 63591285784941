import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Typography, Container, Grid, CircularProgress, FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import { useTrail } from 'react-spring';
import InfiniteScroll from 'react-infinite-scroll-component';
import ArticleCard from '../pages/ArticleCard';

const categories = [
  'hacking', 'cybersecurity', 'infosec', 'social engineering', 'web development',
  'react js', 'python', 'mongodb', 'supabase', '.net',
  'machine learning', 'artificial intelligence', 'software development', 'devops'
];

const NewsFeed = () => {
  const [articles, setArticles] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState('hacking');

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const fetchArticles = useCallback(async (pageNumber) => {
    try {
      const response = await axios.get(`https://hacker-news.firebaseio.com/v0/topstories.json`);
      const storyIds = response.data.slice((pageNumber - 1) * 10, pageNumber * 10);

      const storyPromises = storyIds.map(id => axios.get(`https://hacker-news.firebaseio.com/v0/item/${id}.json`));
      const stories = await Promise.all(storyPromises);
      const articlesData = stories.map(story => story.data);

      if (!articlesData || articlesData.length === 0) {
        setHasMore(false);
      } else {
        setArticles((prevArticles) => {
          const updatedArticles = [...prevArticles, ...articlesData];
          const uniqueArticles = Array.from(new Set(updatedArticles.map(a => a.url)))
            .map(url => updatedArticles.find(a => a.url === url));
          return shuffleArray(uniqueArticles).slice(0, 100);
        });
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error('Error fetching articles:', error);
    }
  }, []);

  useEffect(() => {
    setArticles([]);
    setPage(1);
    setHasMore(true);
    fetchArticles(1);
  }, [fetchArticles]);

  const trail = useTrail(articles.length, {
    from: { opacity: 0, transform: 'translate3d(0,40px,0)' },
    to: { opacity: 1, transform: 'translate3d(0,0px,0)' },
    delay: 200,
  });

  return (
    <Container>
      <Typography variant="h2" component="h1" gutterBottom style={{ textAlign: 'center', marginTop: '20px' }}>
        Hacking News
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel>Category</InputLabel>
          <Select
            value={selectedCategory}
            onChange={(e) => {
              setSelectedCategory(e.target.value);
              setPage(1); // Reset page number when category changes
            }}
            label="Category"
          >
            {categories.map((category) => (
              <MenuItem key={category} value={category}>{category}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <InfiniteScroll
        dataLength={articles.length}
        next={() => fetchArticles(page)}
        hasMore={hasMore}
        loader={<CircularProgress style={{ display: 'block', margin: '20px auto' }} />}
        endMessage={<Typography style={{ textAlign: 'center', margin: '20px 0' }}>No more articles</Typography>}
      >
        <Grid container spacing={4}>
          {trail.map((props, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <ArticleCard style={props} article={articles[index]} />
            </Grid>
          ))}
        </Grid>
      </InfiniteScroll>
    </Container>
  );
};

export default NewsFeed;
